.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.alert {
  color: red;
  text-align: center;
  height: 1em;
}

/* Component CSS */

/* Home CSS */
#conversations {
  padding-top: 56px;
  max-width: 500px;
  margin: auto;
}
#select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
#select button {
  width: 150px;
  margin: 25px;
}

/* Login & Register CSS */
#loginForm,
#registerForm {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  max-width: 300px;
  margin: auto;
  justify-content: center;
  height: 100vh;
}
#loginForm h1, #registerForm h1 {
  text-align: center;
  padding-bottom: 20px;
}

#account {
  text-align: center;
  padding: 25px 0;
}

#errorText {
  text-align: center;
  margin-top: 15px;
  color: red;
}
#newMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
#newMessage h1 {
  margin-top: 76px;
  padding-bottom: 20px;
  text-align: center;
}
#newMessageGroup {
  width: 100%;
  height: 100%;
  max-height: 500px;
  margin: 0;
}
#newMessageText {
  height: 50%;
  margin: 15px 0;
}
#send #conversations {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#addMessage {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
.messageSend {
  display: flex;
  flex-direction: row;
}

/* Messages CSS */
#messages {
  max-width: 500px;
  margin: auto;
  margin-bottom: 76px;
}
#messages h1 {
  padding-top: 80px;
  padding-bottom: 20px;
  text-align: center;
}
#messagesForm {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0;
}
#messagesForm .form-group {
  margin: 0;
  width: 100%;
  max-width: 500px;
}
#messagesForm .form-group input,
#messagesForm .form-group button {
  border-radius: 0;
}
.message {
  max-width: 50%;
}
.date {
  max-width: 30%;
}
#sender {
  background-color: rgba(0, 123, 255, 0.3);
  /* flex-direction: row-reverse; */
}
#sender .message {
  text-align: left;
}
#sender,
#receiver {
  display: flex;
  justify-content: space-between;
}
.send {
  width: 100%;
}
